import { TagManager } from '@accor/ace-ui-core';

export default class AllBillboardFullGrid extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-billboard__fullgrid';

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.setBillboardFullGridClickListener();
    this.adjustImageSpace();
  }

  /*
      Function to overwrite tagmanager for All
    */

  setBillboardFullGridClickListener() {
    let dataLayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    dataLayer = JSON.parse(dataLayer);
    const pageToTarget = dataLayer.pageName.split('::')[1];
    if (dataLayer) {
      if (pageToTarget == 'cobrand') {
        this.componentHost.querySelector('.ace-billboard__fullgrid__content .cmp-button--teritary')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: dataLayer.pageName,
              bloc_name: 'download accorpay app',
              bloc_interaction: 'apple'
            }
          };
          // eslint-disable-next-line no-undef

          TagManager.trackEvent(datasEvent);
        });


        this.componentHost.querySelector('.ace-billboard__fullgrid__content .cmp-button--secondary')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: dataLayer.pageName,
              bloc_name: 'download accorpay app',
              bloc_interaction: 'android'
            }
          };
          // eslint-disable-next-line no-undef

          TagManager.trackEvent(datasEvent);
        });
      }
    }
  }

  adjustImageSpace() {
    const imageItems = document.querySelectorAll('.info-service-template .image');
    if (imageItems) {
      for (let i = 0; i < imageItems.length; i++) {
        const currentElement = imageItems[i];
        if (currentElement.querySelector('.cmp-ace-image-full-width')) {
          const nextElement = currentElement.nextElementSibling;
          const billboardExists = nextElement.querySelector('.ace-billboard__fullgrid[data-variation="billboard-list"]');
          if (nextElement && billboardExists) {
            currentElement.style.marginBottom = '96px';
          }
        }
      }
    }
  }
}


// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(AllBillboardFullGrid.CLASS_NAMESPACE, AllBillboardFullGrid, true);
