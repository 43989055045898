import { TagManager } from '@accor/ace-ui-core';

export default class AllHighlight extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-highlight';

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.highlightTwoBtn = this.componentHost?.querySelectorAll('.ace-highlight__content .ace-button a ');
    this.layerElements = document?.querySelector('[data-cmp-datalayer]');
    this.dataLayers = this.layerElements?.getAttribute('data-cmp-datalayer');
    this.datalayerValues = JSON.parse(this.dataLayers);
    this.bindTagmanager();
    this.brandCorner = this.componentHost.closest('.brand-corner-detail-page-template');
    if (this.brandCorner) {
      this.brandCornerDetail();
    }
  };

  /*
      Function to overwrite tagmanager for Cobrand
    */

  bindTagmanager() {
    let dataLayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    dataLayer = JSON.parse(dataLayer);
    const pageToTarget = dataLayer.pageName.split('::')[1];
    const thematicPageToTarget = dataLayer.pageName.split('::')[0];

    if (dataLayer) {
      if (pageToTarget == 'cobrand') {
        this.componentHost.querySelector('.left-version .cmp-button--tertiary')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: dataLayer.pageName,
              bloc_name: 'accorpay app presentation bloc',
              bloc_interaction: 'subscribe'
            }
          };
          // eslint-disable-next-line no-undef

          TagManager.trackEvent(datasEvent);
        });

        this.componentHost.querySelector('.left-version .ace-highlight__content--link')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: dataLayer.pageName,
              bloc_name: 'presentation block',
              bloc_interaction: 'learn more about all visa cards'
            }
          };
          // eslint-disable-next-line no-undef
          TagManager.trackEvent(datasEvent);
        });

        this.componentHost.querySelector('.right-version .ace-highlight__content--link')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: dataLayer.pageName,
              bloc_name: 'offer bloc',
              bloc_interaction: 'learn more'
            }
          };
          // eslint-disable-next-line no-undef
          TagManager.trackEvent(datasEvent);
        });
      }
      if (pageToTarget === 'homepage') {
        if (this.componentHost.classList.contains('left-version')) {
          this.allHomePage();
        }
        this.Btn = document.querySelector('.highlight .cmp-button.cmp-button--secondary');
        this.BtnTertiary = document.querySelector('.highlight .cmp-button.cmp-button--tertiary');
        this.Btn?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          window.dataLayer.push({
            'event_data': null
          });
          const datasEvent = {
            event: 'GA4event',
            eventName: 'cta_enroll',
            event_data: {
              pagename: dataLayer.pageName,
              form_action: 'enroll',
              enroll_context: 'homepage'
            }
          };
          TagManager.trackEvent(datasEvent);
        });
        this.BtnTertiary?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          window.dataLayer.push({
            'event_data': null
          });
          const datasEvent = {
            event: 'GA4event',
            eventName: 'cta_enroll',
            event_data: {
              pagename: dataLayer.pageName,
              form_action: 'discover program',
              enroll_context: 'homepage'
            }
          };
          TagManager.trackEvent(datasEvent);
        });
      }
      if (thematicPageToTarget === 'thematic') {
        this.thematicBookingTracking();
      }
      if (thematicPageToTarget === 'all-day-use') {
        this.dayUseTracking();
      }
      if (thematicPageToTarget === 'all-content-hub') {
        this.contentHubPages();
      }
    }
  }

  allHomePage() {
    this.blocInteract = '';
    this.highlightTwoBtn?.forEach((item) => {
      item.addEventListener('click', () => {
        if (item.classList.contains('cmp-button--accent')) {
          this.blocInteract = 'enroll';
        } else if (item.classList.contains('cmp-button--secondary')) {
          this.blocInteract = 'discover program';
        }
        const datasEvent = {
          event: 'GA4event',
          eventName: 'bloc_interact',
          event_data: {
            pagename: this.datalayerValues.pageName,
            bloc_name: 'become a member',
            bloc_interaction: this.blocInteract
          }
        };
        window.dataLayer.push({
          'event_data': null
        });
        TagManager.trackEvent(datasEvent);
      }
      );
    }
    );
  }

  brandCornerDetail() {
    this.expFragment = this.componentHost?.closest('.experience-fragment');
    this.highlightButton = this.componentHost?.querySelector('.call-to-action.ace-button--flex .cmp-button--tertiary');
    if (this.expFragment) {
      if (CoreJS.CommonUtils?.userLoginStatus()) {
        this.highlightButton?.classList?.add('btn-hide');
      } else {
        this.highlightButton?.classList?.remove('btn-hide');
      }
    }
  }

  /*
  Need a refactor to avoid code duplication
*/
  thematicBookingTracking() {
    this.highlightTwoBtn?.forEach((item) => {
      item.addEventListener('click', () => {
        if (item.classList.contains('cmp-button--tertiary')) {
          this.blocInteract = 'discover program';
        } else if (item.classList.contains('cmp-button--secondary')) {
          this.blocInteract = 'enroll';
        }
        const pageName = window?.dataLayer.find((el) => el.event_data && el.event_data)?.event_data.pagename;
        const eventData = {
          event: 'GA4event',
          eventName: 'bloc_interact',
          event_data: {
            pagename: pageName,
            enroll_context: `thematic::${item.innerText}`,
            cta_name: this.blocInteract
          }
        };
        TagManager.trackEvent(eventData);
      }
      );
    }
    );
  }

  dayUseTracking() {
    const highlightTitle = this.componentHost?.querySelector('.ace-highlight__content--title');
    const highlightBtns = this.componentHost?.querySelectorAll('.ace-button a');
    if (!highlightBtns || highlightBtns.length === 0) return;

    highlightBtns.forEach(highlightBtn => {
      highlightBtn.addEventListener('click', () => {
        const datasEvent = {
          event: 'GA4event',
          eventName: 'block_interact',
          event_data: {
            pagename: 'day_use',
            block_interaction: 'click',
            block_name: highlightTitle?.innerText || '',
            block_type: 'cta'
          }
        };
        TagManager.trackEvent(datasEvent);
      });
    });
  }


  dealsCornerTracking() {
    this.Btn = document.querySelector('.highlight .cmp-button.cmp-button--secondary');
    this.BtnTertiary = document.querySelector('.highlight .cmp-button.cmp-button--tertiary');
    this.Btn?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      const datasEvent = {
        event: 'GA4event',
        eventName: 'cta_enroll',
        event_data: {
          pagename: 'hotels::promotion::all offer page::global index',

          form_action: 'deals corner',
          enroll_context: 'sign up for free'
        }
      };
      TagManager.trackEvent(datasEvent);
    });
    this.BtnTertiary?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      window.dataLayer.push({
        'event_data': null
      });
      const datasEvent = {
        event: 'GA4event',
        eventName: 'block_interact',
        event_data: {
          pagename: 'hotels::promotion::all offer page::global index',
          block_name: 'loyalty',
          block_interaction: 'program details'
        }
      };
      TagManager.trackEvent(datasEvent);
    });
  }

  contentHubPages() {
    const contentHub = this.componentHost;
    const listItems = contentHub.querySelectorAll('.ace-highlight__content');
    if (!listItems.length) return;

    listItems.forEach((calloutListItem) => {
      const highlightBtns = calloutListItem.querySelectorAll('.ace-button a');
      const highlightTitle = calloutListItem.querySelector('.ace-highlight__content--title');
      const highlightTitleMosaique = calloutListItem.querySelector('.ace-highlight__content--title-mosaique');

      const blockName = highlightTitle ? highlightTitle.innerText : (highlightTitleMosaique ? highlightTitleMosaique.innerText : null);

      if (highlightBtns.length && blockName) {
        highlightBtns.forEach((highlightBtn) => {
          highlightBtn.addEventListener('click', () => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'block_interact',
              event_data: {
                pagename: this.datalayerValues.pageName,
                block_name: blockName,
                block_interaction: 'click',
                block_type: 'highlight'
              }
            };
            TagManager.trackEvent(datasEvent);
          });
        });
      }
    });
  }

}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(AllHighlight.CLASS_NAMESPACE, AllHighlight, true);
