import { BasePageTemplate, TagManager } from '@accor/ace-ui-core';

export default class LpActivityEvent extends BasePageTemplate {
  static CLASS_NAMESPACE = 'lp-activity-and-event';

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.lpActivityEventClass = this.componentHost.classList.contains(`${BasePageTemplate.CLASS_NAMESPACE}`);
    this.pageName = this.componentHost?.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (this.lpActivityEventClass) {
      this.lpActivityEventDetail();
    }
  }

  /*
      Function to tagmanager for brandCorner
    */
  lpActivityEventDetail() {
    let dataLayerJson = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayerJson) {
      dataLayerJson = JSON.parse(dataLayerJson);
    }

    const thematicPage = dataLayerJson.pageName.split('::')[0];

    const calloutBlock = () => {
      const aceCallouts = this.componentHost.querySelectorAll('.ace-callout-container');
      aceCallouts?.forEach((aceContainer, index) => {
        if (index === 0) {
          aceContainer?.querySelectorAll('.ace-callout-section li a')?.forEach((item) => {
            item?.addEventListener(CoreJS.DomEventConstants.CLICK, (ee) => {
              const interactionBlock = `${ee.target.querySelector('.cmp-button__text')?.innerText.trim().replace(/&nbsp;/g, '') || ''}`;
              const datasEvent = {
                event: 'GA4event',
                eventName: 'bloc_interact',
                event_data: {
                  pagename: `${thematicPage === 'thematic' ? dataLayerJson.pageName : 'partnerships::activities::homepage'}`,
                  bloc_name: `${ee.target.closest('.cmp-teaser__content')?.querySelector('.cmp-teaser__pretitle')?.innerText.trim().replace(/&nbsp;/g, '') || ''}`,
                  bloc_interaction: `${thematicPage === 'thematic' ? 'click' : interactionBlock}`,
                  bloc_type: `${thematicPage === 'thematic' ? 'thematic_bloc::hotel' : 'click on cta'}`,
                  bloc_context: 'activities and events'
                }
              };
              window.dataLayer.push({
                'event_data': null
              });
              // eslint-disable-next-line no-undef
              TagManager.trackEvent(datasEvent);
            });
          });
        } else if (index === 1) {
          aceContainer?.querySelectorAll('.ace-carousel__content li a')?.forEach((item) => {
            item?.addEventListener(CoreJS.DomEventConstants.CLICK, (ee) => {
              const interactionBlock = `${ee.target.querySelector('.cmp-button__text')?.innerText.trim().replace(/&nbsp;/g, '') || ''}`;
              const datasEvent = {
                event: 'GA4event',
                eventName: 'bloc_interact',
                event_data: {
                  pagename: `${thematicPage === 'thematic' ? dataLayerJson.pageName : 'partnerships::activities::homepage'}`,
                  bloc_name: `${ee.target.closest('.cmp-teaser__content')?.querySelector('.cmp-teaser__pretitle')?.innerText.trim().replace(/&nbsp;/g, '') || 'best of your activities'}`,
                  bloc_interaction: `${thematicPage === 'thematic' ? 'click' : interactionBlock}`,
                  bloc_type: `${thematicPage === 'thematic' ? 'thematic_bloc::magazine' : 'click on cta'}`,
                  bloc_context: 'activities and events'
                }
              };
              window.dataLayer.push({
                'event_data': null
              });
              // eslint-disable-next-line no-undef
              TagManager.trackEvent(datasEvent);
            });
          });
          aceContainer?.querySelectorAll('.ace-carousel-version-button a')?.forEach((item) => {
            item?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
              const datasEvent = {
                event: 'GA4event',
                eventName: 'bloc_interact',
                event_data: {
                  pagename: `${thematicPage === 'thematic' ? dataLayerJson.pageName : 'partnerships::activities::homepage'}`,
                  bloc_name: 'best of your activities',
                  bloc_interaction: 'see all activities',
                  bloc_type: 'click on cta',
                  bloc_context: 'activities and events'
                }
              };
              window.dataLayer.push({
                'event_data': null
              });
              // eslint-disable-next-line no-undef
              TagManager.trackEvent(datasEvent);
            });
          });
        } else if (index === 2) {
          if (thematicPage === 'thematic') {
            aceContainer?.querySelectorAll('.callout-section li a')?.forEach((item) => {
              item?.addEventListener(CoreJS.DomEventConstants.CLICK, (ee) => {
                const datasEvent = {
                  event: 'GA4event',
                  eventName: 'bloc_interact',
                  event_data: {
                    pagename: dataLayerJson.pageName,
                    bloc_name: `${ee.target.closest('.cmp-teaser__content')?.querySelector('.cmp-teaser__pretitle')?.innerText.trim().replace(/&nbsp;/g, '') || 'best of your activities'}`,
                    bloc_interaction: 'click',
                    bloc_type: 'thematic_bloc::brands'
                  }
                };
                window.dataLayer.push({
                  'event_data': null
                });
                // eslint-disable-next-line no-undef
                TagManager.trackEvent(datasEvent);
              });
            });
          } else {
            aceContainer?.querySelectorAll('.ace-callout-section li a')?.forEach((item) => {
              item?.addEventListener(CoreJS.DomEventConstants.CLICK, (ee) => {
                const datasEvent = {
                  event: 'GA4event',
                  eventName: 'bloc_interact',
                  event_data: {
                    pagename: `${thematicPage === 'thematic' ? dataLayerJson.pageName : 'partnerships::activities::homepage'}`,
                    bloc_name: 'browse our activities',
                    bloc_interaction: `${ee.target.closest('.cmp-teaser__content')?.querySelector('.cmp-teaser__pretitle')?.innerText.trim().replace(/&nbsp;/g, '') || 'click'}`,
                    bloc_type: `${thematicPage === 'thematic' ? 'thematic_bloc::brands' : 'click on cta'}`,
                    bloc_context: 'activities and events'
                  }
                };
                window.dataLayer.push({
                  'event_data': null
                });
                // eslint-disable-next-line no-undef
                TagManager.trackEvent(datasEvent);
              });
            });
          }
        } else if (index === 3) {
          aceContainer?.querySelectorAll('.ace-callout-section li a')?.forEach((item) => {
            item?.addEventListener(CoreJS.DomEventConstants.CLICK, (ee) => {
              const datasEvent = {
                event: 'GA4event',
                eventName: 'bloc_interact',
                event_data: {
                  pagename: `${thematicPage === 'thematic' ? dataLayerJson.pageName : 'partnerships::activities::homepage'}`,
                  bloc_name: 'unforgettable memories',
                  bloc_interaction: `${ee.target.querySelector('.cmp-button__text')?.innerText.trim().replace(/&nbsp;/g, '') || 'click'}`,
                  bloc_type: 'click on cta',
                  bloc_context: 'activities and events'
                }
              };
              window.dataLayer.push({
                'event_data': null
              });
              // eslint-disable-next-line no-undef
              TagManager.trackEvent(datasEvent);
            });
          });
        }
      });
    };
    calloutBlock();
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(BasePageTemplate.CLASS_NAMESPACE, LpActivityEvent, true);
