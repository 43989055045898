import { BasePageTemplate, TagManager } from '@accor/ace-ui-core';

export default class BrandCorner extends BasePageTemplate {
  static CLASS_NAMESPACE = 'brand-corner-detail-page-template';

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.brandCornerDeatil = this.componentHost.classList.contains('brand-corner-detail-page-template');
    const fileNameWithExt = new URL(window.location.href).pathname.split('/').pop();
    const fileNameWithOutExt = fileNameWithExt.split(".")[0];
    if (this.brandCornerDeatil) {
      this.brandCornerDetail(fileNameWithOutExt);
    }
  }

  /*
      Function to tagmanager for brandCorner
    */
  brandCornerDetail(brandName) {
    let dataLayerJson = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayerJson) {
      dataLayerJson = JSON.parse(dataLayerJson);
    }

    const headingHeroBlock = () => {
      const headingHeroButton = this.componentHost.querySelectorAll('.ace-headingpagehero');
      headingHeroButton?.forEach((hhb) => {
        hhb?.querySelector('.cmp-button')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: `${dataLayerJson.pageName || ''}::${brandName || ''}`,
              bloc_name: 'banner cta',
              bloc_interaction: 'brand redirection',
              bloc_type: `${brandName || ''}`
            }
          };
          window.dataLayer.push({
            'event_data': null
          });
          // eslint-disable-next-line no-undef
          TagManager.trackEvent(datasEvent);
        });
      });
    };
    headingHeroBlock();
    const highLightBlock = () => {
      const highlightMedium = this.componentHost.querySelectorAll('.ace-highlight.ace-highlight_mediumlink');
      const highlightLarge = this.componentHost.querySelectorAll('.ace-highlight.ace-highlight--large');
      highlightMedium?.forEach((hmb) => {
        hmb?.querySelector('.cmp-button')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: `${dataLayerJson.pageName || ''}::${brandName || ''}`,
              bloc_name: 'brand',
              bloc_interaction: 'brand redirection',
              bloc_type: `${brandName || ''}`
            }
          };
          window.dataLayer.push({
            'event_data': null
          });
          // eslint-disable-next-line no-undef
          TagManager.trackEvent(datasEvent);
        });
      });
      highlightLarge?.forEach((hlb) => {
        if (hlb.closest('.experiencefragment ')) {
          hlb?.querySelectorAll('.cmp-button')?.forEach((button, index) => {
            if (index === 0) {
              button?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
                const datasEvent = {
                  event: 'GA4event',
                  eventName: 'enroll_interact',
                  event_data: {
                    pagename: `${dataLayerJson.pageName || ''}::${brandName || ''}`,
                    cta_name: 'enroll',
                    enroll_context: 'brand corner'
                  }
                };
                window.dataLayer.push({
                  'event_data': null
                });
                // eslint-disable-next-line no-undef
                TagManager.trackEvent(datasEvent);
              });
            } else {
              button?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
                const datasEvent = {
                  event: 'GA4event',
                  eventName: 'enroll_interact',
                  event_data: {
                    pagename: `${dataLayerJson.pageName || ''}::${brandName || ''}`,
                    cta_name: 'discover program',
                    enroll_context: 'brand corner'
                  }
                };
                window.dataLayer.push({
                  'event_data': null
                });
                // eslint-disable-next-line no-undef
                TagManager.trackEvent(datasEvent);
              });
            }
          });
        } else {
          hlb?.querySelector('.cmp-button')?.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'bloc_interact',
              event_data: {
                pagename: `${dataLayerJson.pageName || ''}::${brandName || ''}`,
                bloc_name: 'flagship hotel block',
                bloc_interaction: 'push flagship hotel',
                bloc_type: `${e.target.closest('.ace-highlight__content')?.querySelector('.ace-highlight__content--title').innerHTML.trim().replace(/&nbsp;/g, '') || ''}`
              }
            };
            window.dataLayer.push({
              'event_data': null
            });
            // eslint-disable-next-line no-undef
            TagManager.trackEvent(datasEvent);
          });
        }
      });
    };
    highLightBlock();
    const socialMediaBlock = () => {
      const socialMediaButton = this.componentHost.querySelectorAll('.ace-icon-list-component .ace-footer-icon__list--item--link');
      socialMediaButton?.forEach((smb) => {
        smb?.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: {
              pagename: `${dataLayerJson.pageName || ''}::${brandName || ''}`,
              bloc_name: 'social media',
              bloc_interaction: 'follow',
              bloc_type: `${e.target?.alt || ''}`
            }
          };
          window.dataLayer.push({
            'event_data': null
          });
          // eslint-disable-next-line no-undef
          TagManager.trackEvent(datasEvent);
        });
      });
    };
    socialMediaBlock();
    const calloutBlock = () => {
      const aceCallouts = this.componentHost.querySelectorAll('.ace-callout-container');
      aceCallouts?.forEach((aceContainer) => {
        aceContainer?.querySelectorAll('.ace-callout-section li a')?.forEach((item) => {
          item?.addEventListener(CoreJS.DomEventConstants.CLICK, (ee) => {
            const datasEvent = {
              event: 'GA4event',
              eventName: 'bloc_interact',
              event_data: {
                pagename: `${dataLayerJson.pageName || ''}::${brandName || ''}`,
                bloc_name: 'hotel',
                bloc_interaction: 'push hotel',
                bloc_type: `${ee.target.closest('.ace-callout-component')?.dataset.productId || ''}`
              }
            };
            window.dataLayer.push({
              'event_data': null
            });
            // eslint-disable-next-line no-undef
            TagManager.trackEvent(datasEvent);
          });
        });
      });
    };
    calloutBlock();
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(BasePageTemplate.CLASS_NAMESPACE, BrandCorner, true);
